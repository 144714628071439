@font-face {
  font-family: 'StidtiPSU';
  src: url('./assets/fonts/PSU_Stidti/psu-stidti-regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'ChuanPimPSU';
  src: url('./assets/fonts/DB_Chuanpim/db_chuanpim_psu_regular.ttf')
    format('truetype');
}

body {
  margin: 0;
  font-family: 'ChuanPimPSU' !important;
  font-size: 22px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(105, 105, 105) !important;
}

button {
  font-family: 'ChuanPimPSU' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form[name='loginBox'] label {
  float: left;
  font-weight: bold;
}

input,
textarea {
  font-family: 'ChuanPimPSU' !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ChuanPimPSU' !important;
}

.primary-psu {
  background-color: rgb(0, 156, 222) !important;
  border-color: rgb(0, 156, 222) !important;
}
